import axios from "axios";
import { mainPath } from "../config/AxiosConfig";
import React, { useEffect, useState } from "react";
import { getSender } from "../config/ChatLogics";
import {
  Box,
  Button,
  HStack,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import SideDrawer from "../components/miscellaneous/SideDrawer";
import ChatBox from "../components/ChatBox";
import { Fade, ScaleFade, Slide, SlideFade, Collapse } from "@chakra-ui/react";
import MyChats from "../components/MyChats";
import { useHistory } from "react-router-dom";
import alarmon from "../images/frontalarmon.png";
import alarm from "../images/alarm.png";
import { ContextScope } from "../Context/ContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { setIsChatDrawerOpen } from "../components/isChatDrawerOpenSlice";
import { setUserInfo } from "../components/slices/userInfoSlice";
import NotesBox from "../components/notes/NotesBox";
import { setDrawerMode } from "../components/slices/drawerModeSlice";
import { format } from "date-fns";
import MyPrivateChats from "../components/MyPrivateChats";
import SinglePrivateChat from "../components/SinglePrivateChat";
import PrivateChatBox from "../components/PrivateChatBox";
import SearchUser from "../components/users/SearchUser";
import ChatLoading from "../components/ChatLoading";
import UserListItem from "../components/UserAvatar/UserListItem";
import { setIsUpdateChatModalOpen } from "../components/slices/isUpdateChatModalOpenSlice";
import { setIsAlarmModalOpen } from "../components/slices/isAlarmModalOpenSlice";
import { setSelectedAlarm } from "../components/slices/selectedAlarmSlice";
import DoneTaskComponent from "../components/task/DoneTaskComponent";
import { setModalMode } from "../components/slices/modalModeSlice";
import io from "socket.io-client";
import { setFetchAgain } from "../components/slices/fetchAgainSlice";
import { setFetchMessagesAgain } from "../components/slices/fetchMessagesAgainSlice";
import { setNewMessageId } from "../components/slices/newMessageIdSlice";

const ChatPage = () => {
  const ENDPOINT = "https://notatkasb-backend.onrender.com";
  var socket, selectedChatCompare;
  // localStorage.removeItem("userInfo");
  const user = useSelector((state) => state.userInfo.userInfo);

  const drawerMode = useSelector((state) => state.drawerMode.drawerMode);
  const [socketConnected, setSocketConnected] = useState(false);
  const [notesPanel, setNotesPanel] = useState(false);
  const [taskPanel, setTaskPanel] = useState(true);
  const [chatPanel, setChatPanel] = useState(false);
  const [chatsPanel, setChatsPanel] = useState(true);
  const [notesColor, setNotesColor] = useState("gray.100");
  const [chatColor, setChatColor] = useState("gray.100");
  const [chatsColor, setChatsColor] = useState("gray.100");
  const [doneComponent, setDoneComponent] = useState(<></>);
  const [chatBoxContent, setChatBoxContent] = useState(<></>);
  const [size, setSize] = useState("sm");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [loadingChat, setLoadingChat] = useState();
  const [searchResult, setSearchResult] = useState();
  const [soc, setSoc] = useState();
  const btnRef = React.useRef();
  const toast = useToast();
  const {
    setChats,
    setSelectedChat,
    alert,
    setAlert,
    privateChats,
    setPrivateChats,
  } = ContextScope();
  const history = useHistory();
  const [top, setTop] = useState(<></>);
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const fetchMessagesAgain = useSelector(
    (state) => state.fetchMessagesAgain.fetchMessagesAgain
  );
  const isChatDrawerOpen = useSelector(
    (state) => state.isChatDrawerOpen.isChatDrawerOpen
  );

  const setFatchAgainz = (par) => {
    // // console.log(par);
  };
  const dispatch = useDispatch();
  // const {user, alert} = ContextScope()
  useEffect(() => {
    setDoneComponent(<DoneTaskComponent chat={selectedTask} mode={"drawer"} />);
  }, [fetchAgain]);
  useEffect(() => {
    if (soc) {
      // console.log("socket:");
      // console.log(soc);
      // console.log(socket);
      // console.log("socket:");
    }
  }, [soc]);

  useEffect(() => {
    soc?.on("message recieved", (newMessageRecieved) => {
      // console.log(newMessageRecieved);
      // console.log(`notification`);
      dispatch(setFetchMessagesAgain(!fetchMessagesAgain));
      dispatch(
        setNewMessageId({
          newMessageId: newMessageRecieved.chat?._id,
          newMessageParentId: newMessageRecieved.chat?.parent,
        })
      );
    });
  });

  useEffect(() => {
    // // console.log(isChatDrawerOpen);
    if (isChatDrawerOpen == true) {
      if (drawerMode === "notes") {
        setNotesColor("gray.300");
      } else if (drawerMode === "chat") {
        setChatColor("gray.300");
        setChatsColor("gray.100");
      } else if (drawerMode === "chats") {
        setChatColor("gray.100");
        setChatsColor("gray.300");
      }
      onOpen();
    } else {
      onClose();
      dispatch(setDrawerMode(""));
    }
  }, [isChatDrawerOpen]);
  const closeChatDrawer = () => {
    dispatch(setDrawerMode(""));
    dispatch(setIsChatDrawerOpen(false));
  };
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));

    if (user) {
      dispatch(setUserInfo(user));
    }
    setTop(<></>);
  }, []);
  useEffect(() => {
    // console.log(`selectedTask`);
    // console.log(selectedTask);
    // console.log(`selectedTask`);
  }, [selectedTask]);
  useEffect(() => {
    if (user._id) {
      // console.log("trying to connect..");
      socket = io(ENDPOINT);
      socket.emit("setup", user);
      socket.on("connected", () => {
        setSocketConnected(true);
        setSoc(socket);
        // console.log(`socket`);
        // console.log(socket);
        // console.log(`socket`);
      });
    }
    if (user?.email) {
      setTop(
        <>
          <SideDrawer
            from="chat"
            setChats={setChats}
            user={user}
            setSelectedChat={setSelectedChat}
            alert={alert}
            setAlert={setAlert}
            privateChats={privateChats}
            setPrivateChats={setPrivateChats}
          />
        </>
      );
    }
  }, [user]);
  const toogleNotes = () => {
    setNotesPanel(!notesPanel);
    if (notesPanel === true) {
      setNotesColor("gray.100");
    } else {
      setNotesColor("gray.300");
    }
  };
  const toogleChats = () => {
    if (chatsPanel === true) {
      setChatPanel(false);
      setChatsPanel(false);
      setChatColor("gray.100");
      setChatsColor("gray.100");
    } else {
      setChatPanel(false);
      setChatsPanel(true);
      setChatColor("gray.100");
      setChatsColor("gray.300");
    }
    // setChatPanel(chatsPanel);
    // setChatsPanel(!chatsPanel);
    // if (chatsPanel === true) {
    //   setChatColor("gray.300");
    //   setChatsColor("gray.100");
    // } else {
    //   setChatColor("gray.100");
    //   setChatsColor("gray.300");
    // }
  };
  const toogleChat = () => {
    if (chatPanel === true) {
      setChatPanel(false);
      setChatsPanel(false);
      setChatColor("gray.100");
      setChatsColor("gray.100");
    } else {
      setChatPanel(true);
      setChatsPanel(false);
      setChatColor("gray.300");
      setChatsColor("gray.100");
    }
    // setChatPanel(!chatPanel);
    // setChatsPanel(chatPanel);
    // if (chatPanel === true) {
    //   setChatColor("gray.100");
    //   setChatsColor("gray.300");
    // } else {
    //   setChatColor("gray.300");
    //   setChatsColor("gray.100");
    // }
  };
  useEffect(() => {
    if ((chatPanel === true || chatsPanel === true) && notesPanel === true) {
      setSize("full");
    } else if (
      chatPanel === false &&
      chatsPanel === false &&
      notesPanel === false
    ) {
      setSize("sm");
    } else {
      setSize("xl");
    }
  }, [notesPanel, chatPanel, chatsPanel]);

  useEffect(() => {
    if (drawerMode === "notes") {
      setNotesPanel(true);
    } else if (drawerMode === "chat") {
      setChatPanel(true);
      setChatsPanel(false);
    } else if (drawerMode === "chats") {
      setChatPanel(false);
      setChatsPanel(true);
    }
  }, [drawerMode]);

  const accessChat = async ({ userToAdd, chatId }) => {
    // console.log(userToAdd);
    try {
      setLoadingChat(true);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.post(
        `${mainPath}/api/chat`,
        { userId: userToAdd._id, chatId },
        config
      );
      if (!privateChats.find((c) => c._id === data._id))
        setPrivateChats([data, ...privateChats]);
      setSelectedChat(data);
      setLoadingChat(false);
    } catch (error) {
      toast({
        title: "error accessing Chat",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };
  const handleAlarm = () => {
    dispatch(setIsAlarmModalOpen(true));
    dispatch(setSelectedAlarm(selectedTask.alarm));
  };
  return (
    <div>
      <span className="top">{top}</span>
      <Box
        className="mainbox"
        justifyContent="space-between"
        w="100%"
        h="91.5vh"
        p="10px"
      >
        {<MyChats />}
        {user && (
          <>
            <Drawer
              // size={size}
              variant="secondary"
              isOpen={isOpen}
              placement="right"
              onClose={closeChatDrawer}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent bg={"white"} height={"100%"}>
                <DrawerBody
                  margin={0}
                  padding={0}
                  top={"0px"}
                  verticalAlign={"top"}
                >
                  <Table height={"100%"} bg={"white"}>
                    <Tbody bg={"white"}>
                      <Tr h={"50px"}>
                        <Td colSpan={3}>
                          <HStack>
                            <Button
                              className={"closeModal"}
                              bg={"transparent"}
                              onClick={closeChatDrawer}
                              p={3}
                              size={"lg"}
                              color={"#aaaaaa"}
                            >
                              <FontAwesomeIcon
                                className="hand"
                                color="gray"
                                icon={faXmark}
                              />
                            </Button>
                          </HStack>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td textAlign={"center"} className="notesTd"></Td>
                        <Td textAlign={"center"} className="notesTd">
                          <VStack>
                            <Button
                              size={"xs"}
                              bg={notesColor}
                              onClick={toogleNotes}
                            >
                              <FontAwesomeIcon
                                color="white"
                                icon={faPenToSquare}
                              />
                            </Button>
                            <Fade
                              in={notesPanel}
                              transition={{
                                exit: { duration: 0.01 },
                                enter: { delay: 0.2, duration: 2 },
                              }}
                            >
                              {notesPanel && (
                                <Text className="drawerInfo">
                                  Notatki prywatne
                                </Text>
                              )}
                            </Fade>
                          </VStack>
                        </Td>
                        <Td textAlign={"center"} className="commentTd">
                          <HStack>
                            <VStack w={"100%"}>
                              <Button
                                size={"xs"}
                                bg={chatColor}
                                onClick={toogleChat}
                              >
                                <FontAwesomeIcon
                                  color="white"
                                  icon={faComment}
                                />
                              </Button>
                              <Fade
                                in={chatPanel}
                                transition={{
                                  exit: { duration: 0.01 },
                                  enter: { delay: 0.2, duration: 2 },
                                }}
                              >
                                {chatPanel && (
                                  <Text className="drawerInfo">
                                    Wiadomości <b>prywatne</b>
                                  </Text>
                                )}
                              </Fade>
                            </VStack>
                            <VStack
                              alignContent={"center"}
                              alignItems={"center"}
                              align={"center"}
                              alignSelf={"center"}
                              w={"100%"}
                              textAlign={"center"}
                            >
                              <Button
                                size={"xs"}
                                bg={chatsColor}
                                onClick={toogleChats}
                              >
                                <FontAwesomeIcon
                                  color="white"
                                  icon={faComments}
                                />
                              </Button>
                              <Fade
                                in={chatsPanel}
                                transition={{
                                  exit: { duration: 0.01 },
                                  enter: { delay: 0.2, duration: 2 },
                                }}
                              >
                                {chatsPanel && (
                                  <Text className="drawerInfo">
                                    Wiadomości <b>publiczne</b>
                                  </Text>
                                )}
                              </Fade>
                            </VStack>
                          </HStack>
                        </Td>
                      </Tr>
                      <Tr h={"95%"}>
                        <Td
                          className="taskTd"
                          h={"100%"}
                          verticalAlign={"top"}
                          minWidth={"300px"}
                        >
                          {taskPanel && (
                            <VStack p={3} w={"100%"} align={"left"}>
                              <Button
                                size={"sm"}
                                bg={"transparent"}
                                maxW={"40px"}
                                onClick={() => {
                                  dispatch(setModalMode("edit"));
                                  dispatch(setIsUpdateChatModalOpen(true));
                                }}
                              >
                                <FontAwesomeIcon
                                  color="#aaaaaa"
                                  icon={faGear}
                                />
                              </Button>
                              <HStack>
                                <Text
                                  className="smaller noMargin"
                                  verticalAlign={"top"}
                                  margin={"0"}
                                  padding={"0"}
                                  color={
                                    selectedTask?.groupAdmin?.some(
                                      (e) => e === user._id
                                    )
                                      ? "blue.600"
                                      : "gray.400"
                                  }
                                >
                                  {selectedTask?.createdAt &&
                                    format(
                                      new Date(
                                        selectedTask?.createdAt?.toString()
                                      ),
                                      "dd/MM/yyyy"
                                    )}
                                </Text>
                                <Text
                                  className="smaller noMargin"
                                  h={"10%"}
                                  verticalAlign={"top"}
                                  margin={"0"}
                                  padding={"0"}
                                  color={
                                    selectedTask?.groupAdmin &&
                                    selectedTask?.groupAdmin?.some(
                                      (e) => e === user._id
                                    )
                                      ? "blue.600"
                                      : "gray.400"
                                  }
                                >
                                  {selectedTask?.groupAdmin &&
                                    selectedTask?.groupAdmin[0]?.name}
                                </Text>
                              </HStack>
                              <VStack
                                className="small"
                                w={"100%"}
                                align={"left"}
                              >
                                <Text>
                                  <b>
                                    <p className="title">
                                      {selectedTask?.users &&
                                      !selectedTask?.isGroupChat
                                        ? getSender(user, selectedTask?.users)
                                        : selectedTask?.chatName}
                                    </p>
                                  </b>
                                </Text>
                                <Box>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: selectedTask?.description,
                                    }}
                                  ></p>
                                </Box>
                              </VStack>
                            </VStack>
                          )}
                        </Td>
                        <Td
                          minWidth={notesPanel ? "400px" : "20px"}
                          w={
                            notesPanel
                              ? chatsPanel || chatPanel
                                ? "50%"
                                : "100%"
                              : "20px"
                          }
                          className="notesTd"
                        >
                          {" "}
                          {notesPanel && (
                            <div className="drawerElement">
                              <NotesBox />
                            </div>
                          )}
                        </Td>
                        <Td
                          minWidth={chatPanel || chatsPanel ? "400px" : "20px"}
                          w={
                            chatPanel || chatsPanel
                              ? notesPanel
                                ? "50%"
                                : "100%"
                              : "20px"
                          }
                          className="commentTd"
                        >
                          {chatPanel && (
                            <div className="drawerElement">
                              {/* <MyPrivateChats /> */}

                              <SearchUser
                                setter={accessChat}
                                title={"wyszukaj pracownika"}
                              />
                              {loading ? (
                                <ChatLoading />
                              ) : (
                                searchResult?.map((user) => (
                                  <UserListItem
                                    key={user._id}
                                    user={user}
                                    handleFunction={() =>
                                      accessChat({
                                        user: user,
                                        chatId: selectedTask?._id,
                                      })
                                    }
                                  />
                                ))
                              )}
                              <PrivateChatBox />
                            </div>
                          )}
                          {chatsPanel && (
                            <div className="drawerElement">
                              <ChatBox user={user} socket={soc} />
                            </div>
                          )}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </>
        )}
      </Box>
    </div>
  );
};

export default ChatPage;

