import {
  Box,
  Button,
  Collapse,
  HStack,
  Td,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { ContextScope } from "../../Context/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { setIsChatDrawerOpen } from "../isChatDrawerOpenSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { format, set } from "date-fns";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { setModalMode } from "../slices/modalModeSlice";
import { setIsUpdateChatModalOpen } from "../slices/isUpdateChatModalOpenSlice";
import { useEffect, useState } from "react";
import DoneTaskComponent from "../task/DoneTaskComponent";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import { setDrawerMode } from "../slices/drawerModeSlice";
import { setSelectedTask } from "../slices/selectedTaskSlice";
import TeamList from "../task/TeamList";
import { setIsAlarmModalOpen } from "../slices/isAlarmModalOpenSlice";
import { setSelectedAlarm } from "../slices/selectedAlarmSlice";
import alarm from "../../images/alarm.png";
import alarmon from "../../images/frontalarmon.png";

const Subtask = ({ subtask, isOpen, index, last, chatDone, opened }) => {
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const {
    alert,
    setAlert,
    selectedChat,
    setSelectedChat,
    setSelectedPrivateChat,
    chats,
    setChats,
  } = ContextScope();
  const drawerMode = useSelector((state) => state.drawerMode.drawerMode);
  const user = useSelector((state) => state.userInfo.userInfo);
  const [done, setDone] = useState(false);
  const [showCollapse, setShowCollapse] = useState(isOpen);
  const [daysLeftBG, setDaysLeftBG] = useState("gray.300");
  const [myClass, setMyClass] = useState("subtaskTr");
  const [isAdmin, setIsAdmin] = useState(false);
  const [showDoneClass, setShowDoneClass] = useState("subtaskTr");
  const [descriptionCollapse, setDescriptionCollapse] = useState(false);
  const [myLastClass, setMyLastClass] = useState("subtaskTr");
  const [daysLeftColor, setDaysLeftColor] = useState("gray.800");
  const dispatch = useDispatch();
  const isChatDrawerOpen = useSelector(
    (state) => state.isChatDrawerOpen.isChatDrawerOpen
  );
  const timeLeft = Math.round(
    (new Date(subtask.dateTo) - Date.now()) / (1000 * 60 * 60 * 24)
  );
  const showDone = useSelector((state) => state.showDone.showDone);
  useEffect(() => {
    if (subtask?.groupAdmin) {
      if (subtask?.groupAdmin?.some((e) => e._id === user._id)) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
    if (timeLeft < 10 && timeLeft > 0) {
      setDaysLeftBG("#ff9326");
      setDaysLeftColor("white");
    } else if (timeLeft <= 0) {
      setDaysLeftBG("red");
      setDaysLeftColor("white");
    } else {
      setDaysLeftBG("gray.300");
      setDaysLeftColor("gray.800");
    }
    if (isOpen === true) {
      if (subtask.done === true) {
        if (showDone === false) {
          setShowCollapse(false);
        } else setShowCollapse(true);
        setDaysLeftBG("#9fba3c");
        setDaysLeftColor("white");
      } else {
        if (isOpen === true) setShowCollapse(true);
      }

      if (index === 0) {
        setMyClass("subtaskTrFirstTop");
        setMyLastClass("subtaskTrLastTop");
      }
      if (index !== 0) {
        setMyClass("subtaskTr");
        setMyLastClass("subtaskTr");
      }
      if (index === last) {
        setMyClass("subtaskTrFirstBottom");
        setMyLastClass("subtaskTrLastBottom");
      }
      if (index === 0 && last === 0) {
        setMyClass("subtaskTrFirstAll");
        setMyLastClass("subtaskTrLastAll");
      }
    } else setShowCollapse(false);
  }, [subtask]);
  const openDrawer = (subtask) => {
    // // console.log(isChatDrawerOpen);
    setSelectedChat(subtask);
    dispatch(setIsChatDrawerOpen(true));
  };
  const openModal = () => {
    dispatch(setModalMode("sub"));
    dispatch(setIsUpdateChatModalOpen(true));
    setSelectedChat(subtask);
  };
  const toast = useToast();
  // useEffect(() => {
  //   setDone(subtask.done);
  // }, [subtask]);
  const handleSubClick = async (target) => {
    setSelectedPrivateChat(null);
    dispatch(setSelectedTask(subtask));
    dispatch(setDrawerMode(target));
    openDrawer(subtask);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const { data } = await axios.put(
        `${mainPath}/api/chat/read`,
        {
          chatId: subtask?._id,
        },
        config
      );

      dispatch(setFetchAgain(!fetchAgain));
    } catch (error) {
      // // // console.log(error);
      // toast({
      //   title: error.response.data.message,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      //   position: "top-left",
      // });
    }
  };
  const dateTo = format(new Date(subtask?.dateTo.toString()), "dd/MM/yyyy");

  const handleAlarm = () => {
    dispatch(setSelectedTask(subtask));
    dispatch(setIsAlarmModalOpen(true));
    dispatch(setSelectedAlarm(subtask.alarm));
  };
  // // console.log(subtask);
  useEffect(() => {
    // if (showDone === false) {
    //   setMyClass("subtaskTr");
    //   setMyLastClass("subtaskTr");
    //   setShowDoneClass("subtaskTr");
    // }
    // // console.log(
    //   `subtask - showDone: ${showDone}, subtask.done: ${subtask.done}`,
    // );

    if (showDone === false && chatDone === true) {
      setShowCollapse(false);
    } else {
      if (showDone === false && subtask.done === true) {
        setShowCollapse(false);
      } else {
        if (opened === true) setShowCollapse(true);
      }
    }
  }, [showDone]);
  return (
    <>
      <Td bg={"#white"} className="subtaskTrZero">
        <Collapse in={showCollapse} animateOpacity></Collapse>
      </Td>
      {/* <Td bg={"#white"} className="subtaskTrZero">
        <Collapse in={showCollapse} animateOpacity></Collapse>
      </Td> */}
      <Td
        bg={"blue.100"}
        className={myClass}
        onClick={() => setDescriptionCollapse(!descriptionCollapse)}
      >
        <Collapse in={showCollapse} animateOpacity>
          <HStack p={"15px 3px 10px 10px"}>
            {subtask.groupAdmin.some((e) => e === user._id).toString() ? (
              <DoneTaskComponent mode="sub" chat={subtask} />
            ) : (
              <Button
                isDisabled={true}
                bg={subtask.done ? "#9fba3c" : "#eaeaea"}
                size={"xs"}
                display={{ base: "flex" }}
              >
                {subtask.done ? (
                  <FontAwesomeIcon color="#ffffff" icon={faCheck} />
                ) : (
                  <FontAwesomeIcon color="#ffffff" icon={faCheck} />
                )}
              </Button>
            )}
            <>
              {(subtask.groupAdmin.some((e) => e._id === user._id) ||
                subtask.groupSuper._id.toString() === user._id.toString()) && (
                <Button
                  bg={"#fafafa"}
                  size={"xs"}
                  display={{ base: "flex" }}
                  onClick={openModal}
                >
                  <FontAwesomeIcon color="#aaaaaa" icon={faGear} />
                </Button>
              )}
            </>
            <Button
              bg={"#fafafa"}
              size={"xs"}
              color="#05cdff"
              onClick={() => {
                handleSubClick("notes");
              }}
            >
              <FontAwesomeIcon color="#de924b" icon={faPenToSquare} />
            </Button>
            <Button
              bg={subtask.newPrivateMessages > 0 ? "#fadede" : "#fafafa"}
              p={"1px 21px 1px 15px"}
              size={"xs"}
              color="#05cdff"
              onClick={() => {
                handleSubClick("chat");
              }}
              leftIcon={<FontAwesomeIcon color="#4bb2de" icon={faComment} />}
            >
              <HStack>
                <span padding={"0px 5px"} className="small lightgray">
                  {subtask.allPrivateMessages}
                </span>
                {subtask.newPrivateMessages > 0 && (
                  <text padding={"0px 5px"} className="small red">
                    {subtask.newPrivateMessages}
                  </text>
                )}
              </HStack>
            </Button>
            <Button
              bg={subtask.newMessages > 0 ? "#fadede" : "#fafafa"}
              p={"1px 21px 1px 15px"}
              size={"xs"}
              color="#05cdff"
              onClick={() => {
                handleSubClick("chats");
              }}
              leftIcon={<FontAwesomeIcon color="#4bb2de" icon={faComments} />}
            >
              <HStack>
                <span padding={"0px 5px"} className="small lightgray">
                  {subtask.allMessages}
                </span>
                {subtask.newMessages > 0 && (
                  <text padding={"0px 5px"} className="small red">
                    {subtask.newMessages}
                  </text>
                )}
              </HStack>
            </Button>
          </HStack>
        </Collapse>
        <Collapse in={showCollapse} animateOpacity>
          <HStack>
            <Text
              paddingLeft={"30px"}
              className="smaller"
              h={"10%"}
              verticalAlign={"top"}
              color={
                subtask.groupAdmin.some((e) => e === user._id)
                  ? "blue.600"
                  : "gray.400"
              }
            >
              {format(new Date(subtask?.createdAt.toString()), "dd/MM/yyyy")}
            </Text>
            <Text
              marginLeft={"30px"}
              className="smaller"
              h={"10%"}
              verticalAlign={"top"}
              color={
                subtask.groupAdmin.some((e) => e === user._id)
                  ? "blue.600"
                  : "gray.400"
              }
            >
              {subtask.groupAdmin[0].name}
            </Text>
          </HStack>
          <HStack
            paddingLeft={"20px"}
            paddingBottom={"10px"}
            onClick={() => {
              handleSubClick("notes");
            }}
          >
            {subtask.description && (
              <p className={showDoneClass}>
                <Button
                  p={"1px 6px 1px 6px"}
                  color={"white"}
                  size={"xs"}
                  onClick={() => {
                    handleSubClick("notes");
                  }}
                >
                  <HStack>
                    {/* <p>czytaj więcej</p>{" "} */}
                    <FontAwesomeIcon
                      color="#90cdf4"
                      size="lg"
                      icon={faEllipsis}
                    />
                  </HStack>
                </Button>
              </p>
            )}
            <Text
              className={subtask.done ? "doneTitle" : "title hand"}
              padding={"3px 3px 10px 5px"}
            >
              <p className={subtask.description && "link"}>
                <b>{subtask.chatName}</b>
              </p>
            </Text>
          </HStack>
        </Collapse>
      </Td>
      {/* <Td
        onClick={() => setDescriptionCollapse(!descriptionCollapse)}
        bg={"blue.100"}
        className={showDoneClass}
      >
      </Td> */}
      <Td bg={"blue.100"} className={showDoneClass}>
        <Collapse in={showCollapse} animateOpacity>
          <HStack>
            <TeamList team={subtask.users} />
            <VStack align={"start"}>
              <p className="small">{subtask.groupSuper?.name}</p>
              <p className="small gray">{subtask.groupSuper?.email}</p>
            </VStack>
          </HStack>
        </Collapse>
      </Td>
      {/* <Td bg={"blue.100"} className={showDoneClass}>
        <Collapse in={showCollapse} animateOpacity>
        </Collapse>
      </Td> */}
      <Td bg={"blue.100"} className={myLastClass}>
        <Collapse in={showCollapse} animateOpacity>
          <HStack className="small gray">
            <Text>{dateTo}</Text>
            <Box
              borderRadius={5}
              color={daysLeftColor}
              bg={daysLeftBG}
              p={"3px 7px 2px 5px"}
            >
              {subtask.done === false ? (
                `${timeLeft} dni`
              ) : (
                <Box p={"0px 4px 0px 4px"}>
                  <FontAwesomeIcon color="#ffffff" icon={faCheck} />
                </Box>
              )}
            </Box>
            <Button width={"40px"} onClick={handleAlarm} size={"sm"}>
              {subtask?.alarm?.length > 0 ? (
                <img alt="set alarm" src={alarmon} />
              ) : (
                <img
                  className="alarmimg"
                  width={"15px"}
                  height={"15px"}
                  src={alarm}
                  alt="set alarm"
                />
              )}
            </Button>
          </HStack>
        </Collapse>
      </Td>
      <Td className="subtaskTrZero">
        <Collapse in={showCollapse} animateOpacity></Collapse>
      </Td>
    </>
  );
};
export default Subtask;
